// as the page loads, call these scripts
jQuery(document).ready(function($) {

    var subMenu = $('#menu-main-menu-2');
    var subMenuActiveItem = subMenu.find('li.current_page_item');
    var subSubMenuActiveItem = subMenu.find('li.current_page_parent');
    subMenu.addClass('nav nav-tabs tabs-left');
    subMenuActiveItem.addClass('active');
    subSubMenuActiveItem.addClass('active')

    // Video IFRAMES får en wrapper
    if(window.location.pathname != "/") { // (undtagen på forsiden)
        $('iframe').each(function( index ){
            $(this).wrap( '<div class="video-wrapper"></div>' );
        })
    }

    $('.slick-slider-front').slick({
        autoplay: true,
        autoplaySpeed: 3500,
        infinite: true,
        fade: true,
        dots: true,
        cssEase: 'ease-in-out',
        nextArrow: '<i class="fa fa-5x hidden fa-angle-right"> </i>',
        prevArrow: '<i class="fa fa-5x hidden fa-angle-left"> </i>',
    });
    $('.slick-slider-front-pris').slick({
        slidesToShow: 3,
        slidesToScroll: 3,
        dots: true,
        nextArrow: '<i class="fa fa-2x fa-angle-right"> </i>',
        prevArrow: '<i class="fa fa-2x fa-angle-left"> </i>',
        responsive: [
            {
              breakpoint: 1600,
              settings: {
                slidesToShow: 3
              }
            },
            {
              breakpoint: 768,
              settings: {
                centerMode: true,
                centerPadding: '20px',
                slidesToShow: 2,
                slidesToScroll: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                centerMode: true,
                centerPadding: '30px',
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false
              }
            }
          ]
    });

    $('.slick-slider-for').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      fade: true,
      asNavFor: '.slick-slider-nav',
      adaptiveHeight: true
    });
    $('.slick-slider-nav').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      asNavFor: '.slick-slider-for',
      dots: true,
      centerMode: true,
      centerPadding: '10px',
      focusOnSelect: true,
        responsive: [
            {
              breakpoint: 768,
              settings: {
                centerMode: true,
                centerPadding: '20px',
                slidesToShow: 2
              }
            },
            {
              breakpoint: 480,
              settings: {
                centerMode: true,
                centerPadding: '30px',
                slidesToShow: 1
              }
            }
          ]
    });
    $('.slick-slider-brochure').slick({
        autoplay: true,
        autoplaySpeed: 3500,
        infinite: true,
        fade: true,
        dots: true,
        cssEase: 'ease-in-out',
        nextArrow: '<i class="fa hidden fa-angle-right"> </i>',
        prevArrow: '<i class="fa hidden fa-angle-left"> </i>',
    });

    $('.slick-slider-front').show();
    $('.slick-slider-front-pris').show();
    $('.slick-slider-for').show();
    $('.slick-slider-nav').show();
    $('.slick-slider-brochure').show();


    //getting viewport width
    var responsive_viewport = $(window).width();

    // if is below 481px
    if (responsive_viewport < 481) {

    }

    //if is larger than 481px
    if (responsive_viewport > 481) {

    }

    // if is above or equal to 768px
    if (responsive_viewport >= 768) {

        // load gravatars
        $('.comment img[data-gravatar]').each(function(){
            $(this).attr('src',$(this).attr('data-gravatar'));
        });

    }

    // off the bat large screen actions
    if (responsive_viewport > 1030) {

    }

});


jQuery(document).ready(function($) {

  //This scripts enables deeper depth in the menu struckture
  $('.menu-item-has-children a').click(function(e) {
  	var parent = $(this).parent().parent().parent()
  	var child = $(this).parent()

  	if (parent.hasClass('menu-item-has-children') && child.hasClass('menu-item-has-children')) {
  		//e.preventDefault()
  		e.stopImmediatePropagation()

  		if (child.hasClass('open')) {
  			child.removeClass('open')
  		} else {
  			child.addClass('open')
  		}
  	}
  })

	$("#mobile-menu").mmenu({
         // Options
  	});

	var API = $("#mobile-menu").data( "mmenu" );
	$("#mobile-menu-button").click(function() {
	 	API.open();
	});
});

/*
  CODY: Vertical timeline
*/
jQuery(document).ready(function($){
  var timelineBlocks = $('.cd-timeline-block'),
    offset = 0.8;

  //hide timeline blocks which are outside the viewport
  hideBlocks(timelineBlocks, offset);

  //on scolling, show/animate timeline blocks when enter the viewport
  $(window).on('scroll', function(){
    (!window.requestAnimationFrame)
      ? setTimeout(function(){ showBlocks(timelineBlocks, offset); }, 100)
      : window.requestAnimationFrame(function(){ showBlocks(timelineBlocks, offset); });
  });

  function hideBlocks(blocks, offset) {
    blocks.each(function(){
      ( $(this).offset().top > $(window).scrollTop()+$(window).height()*offset ) && $(this).find('.cd-timeline-img, .cd-timeline-content').addClass('is-hidden');
    });
  }

  function showBlocks(blocks, offset) {
    blocks.each(function(){
      ( $(this).offset().top <= $(window).scrollTop()+$(window).height()*offset && $(this).find('.cd-timeline-img').hasClass('is-hidden') ) && $(this).find('.cd-timeline-img, .cd-timeline-content').removeClass('is-hidden').addClass('bounce-in');
    });
  }
});


/**********************************************************************
* Angular Application
**********************************************************************/
angular.module('danstein', ['ngMap','ngSanitize','ngResource'])
/* CONTROLLER */
.controller('dansteinController', function ($scope, dansteinService, dansteinCatService, $interval, $timeout) {

    $scope.loading = true; //loading gif
    $scope.stenhuggerier = []
    $scope.pos = [56.162939, 10.203921]
    $scope.zoom = 11
    $scope.chosenHugger = {}
    $scope.marker = {}
    dansteinService.get().success(function(data) {
        $scope.stenhuggerier = data;
    })
    .catch(function (err) {
      console.error('stenhugger GET error')
    })
    .finally(function () {
      $scope.loading = false;
      //console.log('Hentet alle stenhuggerier')
    });

    dansteinCatService.get().success(function(data) {
        $scope.lokationer = data;
    })
    .catch(function (err) {
      console.error('lokation GET error')
    })
    .finally(function () {
      $scope.loading = false;
      //console.log('Hentet alle kategorier')
    });

    //clearFilter function
    $scope.clearFilter = function() {
      console.info("Filter cleared");
      $scope.FriTekst = ''; // fritekstsøgning
      $scope.placering = ''; // lokationssøgning
      $scope.id = '';
      $scope.chosenHugger = {};
      $scope.pos = [56.162939, 10.203921];
      $scope.zoom = 11;
    };

    $scope.markerClick = function(e, hugger) {
      console.log($scope.marker) 
      if (!hugger) {
        hugger = e
      }
      console.log(hugger)
      $scope.id = hugger.id;
      $scope.chosenHugger = hugger
      $scope.zoom = 12
      $scope.pos = [hugger.acf.stenhugger_map.lat, hugger.acf.stenhugger_map.lng]
    }

}).controller('dansteinPrisController', function ($scope, Priseksempel, dansteinPrisService, $interval, $timeout) {

    $scope.loading = true; // loading gif

    $scope.gravsteder       = Priseksempel.gravsted.query();
    $scope.stentyper        = Priseksempel.stentype.query();
    $scope.farver           = Priseksempel.farve.query();
    $scope.inskriptioner    = Priseksempel.inskription.query();
    $scope.granittyper      = Priseksempel.granittype.query();
    $scope.tilbehor         = Priseksempel.tilbehor.query();

    dansteinPrisService.get().success(function(data) {
        $scope.priser = data;
    })
    .catch(function (err) {
      console.error('priseksempel GET error')
    })
    .finally(function () {
      $scope.loading = false; // loading gif
    });

    // clearFilter function
    $scope.clearFilter = function() {
        console.info("Filter cleared");
        $scope.gravsted         = '';
        $scope.stentype         = '';
        $scope.inskription      = '';
        $scope.tilbehor         = '';
        $scope.searchString     = ''; // fritekstsøgning
        $scope.farve            = '';
        $scope.granit           = '';
        $scope.sortPrice        = '';
    };

}).service('dansteinService', function ($http){

   return {
       get: function() { //henter alle stenhuggerier
           return $http({
               url: '/wp-json/wp/v2/stenhugger?orderby=title&order=asc&per_page=100',
               method: "GET",
               per_page: 99
           });
       }
   };

}).service('dansteinCatService', function ($http){

   return {
       get: function() { //henter alle stenhuggerier
           return $http({
               url: '/wp-json/wp/v2/categories?exclude=1',
               method: "GET",
               per_page: 99
           });
       }
   };

}).service('dansteinPrisService', function ($http){

   return {
       get: function() { //henter alle stenhuggerier
           return $http({
               url: '/wp-json/wp/v2/priseksempel?orderby=title&order=asc&per_page=100',
               method: "GET",
               per_page: 99
           });
       }
   };

}).factory('Priseksempel', function($resource) {

    return {
        gravsted: $resource('/wp-json/wp/v2/gravsted', {per_page: '100' }, {
            query: { method: 'GET', params: {}, isArray: true }
        }),
        stentype: $resource('/wp-json/wp/v2/stentype', { per_page: '100' }, {
            query: { method: 'GET', params: {}, isArray: true }
        }),
        farve: $resource('/wp-json/wp/v2/farve', { per_page: '100' }, {
            query: { method: 'GET', params: {}, isArray: true }
        }),
        inskription: $resource('/wp-json/wp/v2/inskription', { per_page: '100' }, {
            query: { method: 'GET', params: {}, isArray: true }
        }),
        granittype: $resource('/wp-json/wp/v2/granittype', { per_page: '100' }, {
            query: { method: 'GET', params: {}, isArray: true }
        }),
        tilbehor: $resource('/wp-json/wp/v2/tilbehor', { per_page: '100' }, {
            query: { method: 'GET', params: {}, isArray: true }
        })
    };

}).directive('backImg', function(){
    return function(scope, element, attrs){
        var url = attrs.backImg;
        element.css({
            'background-image': 'url(' + url +')'
        });
    };
}).filter('removeSpaces', [function() {
    // Remove spaces for telefon links.
    return function(string) {
        if (!angular.isString(string)) {
            return string;
        }
        return string.replace(/[\s]/g, '');
    };
}]);
